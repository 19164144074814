import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../../components/layout';
import Section from '../../components/section';

const title = 'Integrations for Atlassian & Google Analytics';

const IntegrationsForGoogleAnalyticsPage = () => (
  <>
    <GatsbySeo
      noindex={true}
      language="en"
      title={title}
      description="Integration of Atlassian Jira & Confluence data collection and reporting with Google Analytics"
    />
    <Layout pageTitle={title}>
      <Section>
        <section className="text-gray-600 dark:text-gray-400 body-font">
          <div className="container flex md:flex-row flex-col items-center">
            <div className="lg:flex-grow flex flex-col items-start md:text-left text-left">
              <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-4xl title-font">
                {title}
              </h1>
              <h2 className="dark:text-gray-200 my-8 font-medium leading-relaxed">
                Atlassian's Confluence and Jira
              </h2>

              <StaticImage
                class="rounded-lg m-6 border-4 border-slate-400"
                src="./gaic-overview.png"
                alt="Screenshot of Integration of Atlassian Confluence reporting with Google Analytics"
              />

              <div>
                <div className="animate-underline-anchors content-body">
                  <p>
                    Our integrations for Google Analytics and Atlassian's
                    Confluence and Jira allow you to see the information you
                    need in context where you need it.
                  </p>
                  <p>
                    Get invaluable insight into your content performance, from
                    high-level usage to identifying popular content, search
                    terms, or areas for improvement.
                  </p>
                  <p>
                    ​ Get rich insight into where your users are engaging with
                    content and each other, and more importantly, where they’re
                    not.
                  </p>
                  <h2 id="permissions-used">Permissions used</h2>
                  <h3 id="ua">Universal Analytics integrations (Legacy)</h3>
                  <ul>
                    <li>
                      These integrations are being phased out and will stop
                      collecting data on July 1, 2023.
                    </li>
                    <li>
                      These integrations are built using only static HTML and
                      JavaScript. They access the Google Analytics data directly
                      in the browser and do not save your data anywhere.
                    </li>
                  </ul>
                  <h4 id="ua-scopes">Scopes</h4>
                  <p>They use the following OAuth 2.0 scope to access data.</p>
                  <ul>
                    <li>
                      <a href="https://www.googleapis.com/auth/analytics.readonly">
                        https://www.googleapis.com/auth/analytics.readonly
                      </a>
                    </li>
                  </ul>
                  <p>
                    These integrations only have access to display your data if
                    you are currently logged into Google and have authorised the
                    app to connect to Google Analytics.
                  </p>
                  <h3 id="ga4">Google Analytics 4 (GA4) integrations</h3>
                  <ul>
                    <li>
                      These integrations are build using both server side and
                      client side technologies.
                    </li>
                    <li>
                      They store a token for you to use that allows you to
                      access Google Analytics information without the need to
                      reauthenticate.
                    </li>
                    <li>
                      They only access your data when you, or a person whom you
                      have delegate access to the reports, accesses the app
                      within Atlassian Confluence or Jira.
                    </li>
                    <li>
                      You can revoke access to the Google APIs at any time. This
                      will remove the token from the apps' data store and also
                      remove access permissions at Google.
                    </li>
                  </ul>
                  <h4 id="ga4-scopes">Scopes</h4>
                  <p>They use the following OAuth 2.0 scopes to access data.</p>
                  <ul>
                    <li>
                      <strong>User info scopes</strong> to display the logged in
                      user's profile to display in the admin screen
                      <ul>
                        <li>
                          <a href="https://www.googleapis.com/auth/userinfo.email">
                            https://www.googleapis.com/auth/userinfo.email
                          </a>
                        </li>
                        <li>
                          <a href="https://www.googleapis.com/auth/userinfo.profile">
                            https://www.googleapis.com/auth/userinfo.profile
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Google Analytics scopes</strong> to allow
                      management of analytics property, and access to reporting
                      data
                      <ul>
                        <li>
                          <a href="https://www.googleapis.com/auth/analytics">
                            https://www.googleapis.com/auth/analytics
                          </a>
                        </li>
                        <li>
                          <a href="https://www.googleapis.com/auth/analytics.readonly">
                            https://www.googleapis.com/auth/analytics.readonly
                          </a>
                        </li>
                        <li>
                          <a href="https://www.googleapis.com/auth/analytics.edit">
                            https://www.googleapis.com/auth/analytics.edit
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Section>
    </Layout>
  </>
);

export default IntegrationsForGoogleAnalyticsPage;
